import '../App.css'
import About from './About'
import Projects from './Projects'
import bg from '../resources/stsky.JPG'

import {Col, Row, Container, Image, } from 'react-bootstrap'


//  NOTE TO SELF WHEN STARTING BACK UP:
// RUN npm i in the terminal
// then npm start to get local host


export default function Home(){

    window.addEventListener('load', (event) => {
        for (let slide of document.querySelectorAll(".slide")) slide.classList.add("loaded");
      });

    return(
        <div className='main' id="home">
            
            <div className="navroom" ></div>
            <Container fluid>
                <div className="home " >
                <br/>
                <br/>
                {/* style={{ backgroundColor: "hsla(120, 60%, 70%, 0.3)"}} */}

                <div className="slidee ">
                <h1  style={{
                    display: "grid",
                    justifyContent: "center",
                    marginTop: '25vh'
                }} ><span className="blurred-box">  GRANT WILKIE <br/> Software Engineer  </span></h1>
                </div>

                </div>
                <hr/>
                <div className="about" id="about">
                    <About/>
                    
                </div>
                <hr/>
                <div className="projects" id="projects">
                    <Projects/>

                </div>

            </Container>

        <style>{`
            .projects{
                padding-top: 60px;
                margin-bottom: 25px;
            }
            .navroom{
                // padding: 25px;
            }
            h1 span{
                background-color: hsla(0, 0%, 80%, 0.5);
                padding: 10px;
                
            }
            
            .about{
                padding-top: 40px;
            }
            


            div.slide.loaded {
                width:100%;
                overflow:hidden;
              }
            div.slide.loaded {
                animation: 4s slide ;
                background-color: none;
              }
              
            @keyframes slide {
                from {
                  margin-left: 100%;
                  width: 300%; 
                }
              
                to {
                  margin-left: 0%;
                  width: 100%;
                }
              }

            Container{
                max-width: 100%;
            }

            .main{
                background-color: #0c120a;
                color: black;
                font-weight: 555;
            }
            h1{
                font-weight: 900;
                color: black;
            }
            *{
                color: white;
                
            }
           


        `}</style>


        </div>
    )
}