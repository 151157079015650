import {Nav, Navbar, Container, NavDropdown, Modal, Button} from 'react-bootstrap'

import '../App.css'
import {Link, useHistory} from 'react-router-dom'
import { useEffect, useState } from 'react';
import resume from '../resources/Grant Wilkie Resume.pdf'
import { ImGithub } from 'react-icons/im'
import { SiLinkedin } from 'react-icons/si'
import { GrInstagram } from 'react-icons/gr'
import { GrMail } from 'react-icons/gr'
import { SiTiktok } from 'react-icons/si'


export default function Navigation(){

    const [show, setShow] = useState(false);
    function Example() {
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
            
            <a className="nav-link" onClick={handleShow}><GrMail/></a>
      
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Contact:</Modal.Title>
              </Modal.Header>
              <Modal.Body id="contact-text">
                  Please feel free to reach out with any questions or inquiries. 
                  <br/><br/>
                  Email: Grant.S.Wilkie@gmail.com 
                  <br/><br/>
                  Phone: 414-336-2125




              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                
              </Modal.Footer>
            </Modal>
          </>
        );
      }
      


    return(
        <div className="fixed-top top">

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
            <Navbar.Brand href="/">Grant Wilkie</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <a className="nav-link" href="/"  >Home</a>
                    <a className="nav-link" href="#about">About</a>
                    <a className="nav-link" href="#projects">Projects</a>
                    <a className="nav-link" target="_blank" href={resume} >Resume</a>
                </Nav>
                <Nav>
                    <Example />
                    <a className="nav-link" target="_blank" href="https://github.com/grant-create"><ImGithub/> </a>
                    
                    <a className="nav-link" target="_blank" href="https://www.linkedin.com/in/grant-wilkie/"><SiLinkedin /> </a>
                    
                    <a className="nav-link" target="_blank" href="https://www.instagram.com/grantswilkie/"><GrInstagram/> </a>
                   
                    <a className="nav-link" target="_blank" href="https://www.tiktok.com/@fixedge4r?"><SiTiktok/> </a>

                    
                    
                    
                    
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar> 




        <style>{`
        .top{
            background-color: white;
            padding-bottom: -15px;
        }
        
        `}</style>

        </div>
    )
}