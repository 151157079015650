
import {Col, Row, Container, Image, Button} from 'react-bootstrap'




import p1Pic from '../resources/p1Pic.JPG'
import p2Pic from '../resources/p2Pic.JPG'
import p3Pic from '../resources/p3Pic.JPG'
import p4 from '../resources/p4.JPG'
// import hb from '../resources/hb.JPG'




export default function Projects(){
    return(
        <div className="projectsSection">
            <Container fluid>
                <h3>PROJECTS</h3>
                <br/>
                <Row className='projectrow'  >

                    {/* ------------------------------------------------------------------------ */}

                        <h4>Algo Trader</h4>
                        <h7>Python, Flask</h7>
                        <br/>
                        <br/>
                        {/* https://p4-algo.herokuapp.com/ */}
                        
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className='p'>
                        

                        <a  href="#" target="_blank">
                                <Image width="600rem" height="auto" className="img" src={p4} alt="project 4"   /></a>
                    </Col>
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className='p'>
                            
                        <div className="projectInfo" style={{height:"400px", maxWidth:"400px"}}>
                            
                            <p style={{textAlign: "left", fontSize: "15px"}}>This web app displays the output from a continuously running algorithm that checks every stock in the S&P500 and buys based on the 20 day breakout status.
                            <br/>
                            <br/>
                            Flask built Python algorithm that runs automatically from an Alpaca brokerage account and buys and sell securities based on specified parameters.
                            <br/>
                            </p>
                            <Button  size="sm" variant="outline-primary">
                                 <a style={{textAlign: "center"}} href="https://github.com/grant-create/p4-flask-algo-trader" target="_blank"> Github </a>
                            </Button>
                            {/* <Button  size="sm" variant="outline-success"><a  href="#" target="_blank">Live Site </a>
                            </Button> */}
                        </div>
                        <br/>
                    </Col>
                    <br/>
                </Row>
                <br/>
                {/* ------------------------------------------------------------------------ */}
                <Row className='projectrow'>
                    

                    <h4>Park Hopper</h4>
                    <h7>React, MongoDB, Express, Node, Bootstrap </h7>
                    <br/>
                    <br/>
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className='p'>
                        <a href="https://parkhopper.netlify.app/" target="_blank">
                            <Image width="600rem" height="auto" className="img" src={p3Pic} alt="project 3 gif"  /> 
                        </a>
                                
                    </Col>
                    <Col lg="auto" md="auto" sm="auto" xs="auto"  className='p'>
                            
                        <div className="projectInfo" style={{height:"400px", maxWidth:"400px"}}>
                            <p style={{textAlign: "left", fontSize: "15px"}}>
                               This award-winning National Parks web app lets you find and filter parks and activities with details from each. 
                            <br/>
                            <br/>
                                A 48hr Hackathon project built in collaboration with a team of four UX/UI designers and our team of software engineers.
                            <br/>
                            <br/>
                                Built on a MongoDB database that stores login information and user favorited parks. 
                           
                            


                            </p>
                            <span>
                                <Button  size="sm" variant="outline-primary"><a  href="https://github.com/grant-create/client-side-503" target="_blank">Github Front End </a></Button>

                                <Button  size="sm" variant="outline-primary"><a  href="https://github.com/grant-create/mern-auth-server-503" target="_blank">Github Back End</a></Button>

                                <Button  size="sm" variant="outline-success"><a  href="https://parkhopper.netlify.app/" target="_blank">Live Site </a></Button>
                            </span>
                                            </div>
                            
                                            
                        
                        
                        <br/>
                        

                    </Col>
                   
                </Row>
                <br/>
                <Row className='projectrow'>
                    <br/>
                    {/* ------------------------------------------------------------------------ */}
                    
                        <h4>Stock Analysis Project</h4>
                        <h7>Express, PostgreSQL, JavaScript, Node</h7>
                        <br/>
                        <br/>
                        
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className='p'>

                        
                        <a href="https://stockanalysisproject.herokuapp.com/" target="_blank">
                            
                        <Image width="600rem" height="auto" className="img" src={p2Pic} alt="project 2 gif"  /> </a>

                        </Col>
                        <Col lg="auto" md="auto" sm="auto" xs="auto" className='p'>
                            <div className="projectInfo" style={{height:"400px", maxWidth:"400px"}}>
                                <p style={{textAlign: "left", fontSize: "15px"}}>A web app consisting of three tables that show 20 and 70 day breakouts, securities that closed below their 20 day average, and the opinions of Reddit's Wall Street Bets. 
                                <br/>
                                <br/>
                                 The user can add custom stocks, filter by price, and update all information within the database.
                                <br/>
                                <br/>
                                SQL databases with a variety of indicators and historical data that uses API data from Alpaca and Reddit Wall Street Bets.

                                </p>
                                <Button  size="sm" variant="outline-primary"><a  href="https://github.com/grant-create/Stock-Analyzer" target="_blank">Github</a></Button>
                                <Button  size="sm" variant="outline-success"><a  href="https://stockanalysisproject.herokuapp.com/" target="_blank">Live Site</a></Button>
                            </div>
                            
                        
                        <br/>
                    </Col>
                    <br/>
                    
                </Row>
                <br/>
                    {/* ------------------------------------------------------------------------ */}
                <Row className='projectrow'>
                    
                    <h4>Night of the Living Hedge</h4>
                    <h7>JavaScript, CSS, HTML</h7>
                    <br/>
                    <br/>
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className='p' >
                        <a href="https://grant-create.github.io/Night-of-the-Living-Hedge/" target="_blank">
                            <div className="p4" style={{textDecoration:'none'}}>
                                <Image width="600rem" height="auto" className="img" src={p1Pic} alt="project 1 Pic"  />
                            </div>
                        </a>
                    </Col>
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className='p' >
                                <div className="projectInfo" style={{height:"400px", maxWidth:"400px"}}>
                                    <p style={{textAlign: "left", fontSize: "15px"}}>
                                    This old school Pac-Man spinoff is inspired by the retro arcades of the '80s.
                                    <br/><br/>
                                        A web game that uses JavaScript DOM manipulation, HTML, and CSS.
                                        <br/><br/>
                                        Includes multiple game-modes, high score, randomized enemies, and bonus items.
                                        <br/><br/>
                                        Use the arrow keys to collect tokens and avoid zombies!
                                    </p>
                                    <Button  size="sm" variant="outline-primary"><a  href="https://github.com/grant-create/Night-of-the-Living-Hedge" target="_blank">Github</a></Button>
                                    <Button  size="sm" variant="outline-success"> < a href="https://grant-create.github.io/Night-of-the-Living-Hedge/" target="_blank">Live Site</a></Button>
                                </div>
                    </Col>


                </Row>
                <br/>
                {/* <div className="spaceatbottom"></div> */}

                <style>{`
              
                
                h4{
                    font-weight: 900;
                    padding-top: 10px;
                }

                a{
                    color: white;
                    text-decoration: none;
                }

                p{
                    padding: 20px; 
                }

                Button{
                    margin: 5px;
                    background-color: white;
                    
                }

                .p{
                    
                    margin: 0 auto;
                    margin-bottom: 1em;

                  
                }

    
                .projectrow{
                    // background-color: silver;
                    border: solid silver 1px;
                    max-width: 80em
                    margin: 0 auto;
                }

                .projectInfo{
                    // background-color: ;
                }
 


                .p { 
                    transition: all .2s ease-in-out; 
                }
                
                .p:hover { 
                    transform: scale(1.05); 
                }
               .img{
                   max-width: 80%;
                   max-height: 100%;
               }
               .spaceatbottom{
                   padding-bottom:10px;
               }



    
            `}</style>

            </Container>
        </div>
    )
}
