
import { useEffect } from 'react'
import {Col, Row, Container, Image, Table,} from 'react-bootstrap'

import profpic from '../resources/sqprofpic.jpg'
import profpic1 from '../resources/standingprof.jpeg'
import numpy from '../resources/numpy.png'
import next from '../resources/next-js.svg'

export default function About(){

    const bothPics = [profpic, profpic1]
    let x = Math.floor(Math.random() * 2)
    const pic = () => {
        
        return bothPics[x]

    }
    pic()
    useEffect(() => {
        pic()
    },[])

    return(
        <div>
           <div className="bigarea ">
               <br/>
               

               
                <h3>ABOUT ME</h3>
                <hr/>
                    <div className="" >
                    <Container fluid='md'>
                        <br/>
                        <Row>

                            
                                <Col lg="auto" md="auto" sm="auto" xs="auto" className="picCol"  >
                                    <Image className="pic" src={bothPics[0]} alt="Profile Picture" />
                                </Col>
                            
                            
                                <Col   >
                                    <div className="text" >
                                        <p id='bio'>
                                        Hi, I’m Grant. I am an action-oriented Software Engineer with a passion for efficiency and creative problem solving. I’m fascinated by the intersection of technology and environmentalism, and how we can harness digital tools to promote sustainability and conservation. 
                                        <br/>
                                        <br/>

                                        My side passion? Building financial trading applications for both first time and experienced investors. Financial literacy is increasingly important and I aim to build resources that make the stock market both accessible and digestible for everyone.
                                        <br/>
                                        <br/>

                                        With a BS in Environmental Science and a background in Supply Chain, my strong attention to detail gives me the flexibility to excel on both independent and team projects. My goal is to make an impact on those around me through developing software to improve lives and help others.                                        
                                        </p>
                                    </div>
                                

                                    {/* <p><u>Hobbies:</u></p>
                                    <p> Hiking, Kayaking, Camping, Chess, Bicycling, Gardening, Swimming </p> */}

                                </Col> 
                            
                        </Row> 
                          
                        
                    <hr/>
                    <div>
                        <div id="keySk">


                            <h3>KEY SKILLS</h3>  
                            <br/>
                            <Row>
                                <Col>
                                    <img className="techicon" src="https://img.icons8.com/color/48/000000/python--v1.png"/>
                                    <p>Python</p>
                                </Col>
                                <Col>
                                    <img className="techicon" src="https://img.icons8.com/color/48/000000/react-native.png"/>
                                    <p>React.JS</p>
                                </Col>
                                <Col>
                                    <img className="techicon" src="https://img.icons8.com/color/48/000000/javascript--v1.png"/>
                                    <p>JavaScript</p>
                                </Col>
                                <Col>
                                    <img className="techicon" src="https://img.icons8.com/color/48/000000/mongodb.png"/>
                                    <p>MongoDB</p>
                                </Col>
                            </Row>
                            <Row>
                                        
                                    <Col>
                                        <img className="techicon" src={numpy} style={{width: "48px", height: "48px"}} />
                                        <p>NumPy</p>
                                    </Col>
                                    <Col>
                                        <img className="techicon" src="https://img.icons8.com/color/48/000000/nodejs.png"/>
                                        <p>Node.JS</p>
                                    </Col>
                                    <Col>
                                        <img className="techicon" src="https://img.icons8.com/color/48/000000/postgreesql.png"/>
                                        <p>PostgreSQL</p>
                                    </Col>
                                    <Col>
                                        <img className="techicon" src="https://img.icons8.com/color/48/000000/django.png"/>
                                        <p>Django</p>
                                    </Col>

                            </Row>
                        </div>
                        <hr/>
                        <h3>ALL SKILLS</h3> 
                        

                        <Table  responsive="sm" className='table' striped bordered hover variant="dark" >
                        <tbody>
                        
                            <tr>
                            <th>Languages</th>
                            <td>Python</td>
                            <td>JavaScript/ES6</td>
                            <td>HTML5</td>
                            <td>CSS3</td>
                            </tr>
                            <tr>
                            <th>Libraries</th>
                            <td>NumPy</td>
                            <td>Pandas</td>
                            <td>Bootstrap</td>
                            <td>EJS</td>
                            </tr>
                            <tr>
                            <th>Frameworks</th>
                            <td>Django</td>
                            <td>React</td>
                            <td>Express</td>
                            <td>Node</td>
                            </tr>
                            <tr>
                            <th>Databases</th>
                            <td>MongoDB</td>
                            <td>Mongoose</td>
                            <td>PostgreSQL</td>
                            <td>Sequelize</td>
                            </tr>
                            <tr>
                            <th>Other</th>
                            <td>RESTful Routing</td>
                            <td>Git/Github</td>
                            <td>JSON</td>
                            <td>MERN</td>
                            </tr>
                            
                        </tbody>
                        </Table>           
                    </div>       


                 
                </Container>
                    </div>     
                             

            </div>


        <style>{`
        .pic{
            max-width: 40vh;
        }

        #bio {
            text-align: left;
        }

        #keySk{
            background-color: #000000;
            color: black;
        }

    // // for making the icons spin 
    //     // .techicon{
    //     //     transition: transform .2s;
    //     // }

    //     // .techicon:hover {
            
    //     //     animation: rotation .5s infinite linear;
    //     //   }
          
    //     //   @keyframes rotation {
    //     //     from {
    //     //       transform: rotate(0deg);
    //     //     }
    //     //     to {
    //     //       transform: rotate(359deg);
    //     //     }
    //     //   }
        .techicon { 
            transition: all .2s ease-in-out; 
            }
            
            .techicon:hover { 
            transform: scale(1.3); 
            }
        Table { 
            transition: all .2s ease-in-out; 
            }
            
            Table:hover { 
            transform: scale(1.1); 
            }
         
        




        `}</style>




    </div> 
    )
}