import logo from './logo.svg';
import './App.css';
import Home from './components/Home.jsx'
import About from './components/About.jsx'
import Projects from './components/Projects.jsx'
import Navigation from './components/Navigation.jsx'
import Footer from './components/Footer.jsx'
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  Redirect
} from 'react-router-dom'
import {
  useState,
  useEffect
} from 'react'
import ReactGA from 'react-ga'



function App() {

  const TRACKING_ID = "UA-204674607-1"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Router>
      <header>
        <Navigation />
      </header>

      <div className="App">
        <Switch>
          <Route 
            exact path="/"
            render={() => <Home/>}
          />

          <Route 
            path="/projects"
            render={ () => <Projects /> }
          />

          <Route 
            path="/about"
            render={ () => <About/> }
          />

          {/* <Route 
            path = "/contact"
            render={() => <Contact/> }
          
          /> */}
          
        </Switch>
      </div>
      
    </Router>
  );
}

export default App;
